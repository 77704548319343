<template>
  <v-container class="container d-flex justify-center pa-0" fluid>
    <MySnackbar v-model="alert.show" :type="alert.type" :text="alert.text" />
    <v-main
      class="content left-bg white--text"
      :style="
        'background-image: url(' +
          ($store.state.settings.login_media
            ? $store.state.settings.login_media.url
            : require('../../assets/login-bg.jpg')) +
          ');'
      "
    >
      <v-row class="height-100 text-center" align="center" justify="center">
        <v-col cols="11" sm="8" md="6">
          <v-avatar tile size="100">
            <v-img :src="require('@/assets/img/box-logo-FA.png')" contain />
          </v-avatar>

          <v-stepper
            v-model="step"
            class="elevation-0 pa-0"
            style="background-color: transparent"
          >
            <v-stepper-content step="1" class="pa-0">
              <h3 class="mt-2 white--text">ثبت نام</h3>
              <v-form
                ref="form"
                @submit.prevent="PostRegisterInfo"
                class="mt-2"
              >
                <v-form-base
                  :row="rowAttribute"
                  :col="{ cols: 12, md: 6 }"
                  :model="model"
                  :schema="schema"
                />

                <v-row>
                  <v-col cols="12" md="6" order="2" order-md="1">
                    <v-btn
                      class="my-4 white--text moholand-rounded font-weight-bold"
                      elevation="0"
                      color="orange"
                      block
                      x-large
                      type="submit"
                      :loading="loading"
                    >
                      ثبت نام
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="6" order="1" order-md="2">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-checkbox v-model="model.is_seller" dark>
                          <template #label>
                            <div class="label">میخوام فروشنده بشم.</div>
                          </template>
                        </v-checkbox>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-checkbox
                          v-model="model.isLawAccepted"
                          dark
                          :rules="[rules.required]"
                          label="قوانین سایت را قبول دارم"
                        >
                          <template #label>
                            <div class="label">
                              <a @click="$router.push('/page/rules')">
                                قوانین
                              </a>
                              سایت را قبول دارم
                            </div>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="2" class="pa-0">
              <v-form ref="form2">
                <v-row justify="center" align="center">
                  <v-col cols="8">
                    <div style="display: flex; flex-direction: row;">
                      <Otp
                        v-model="otp"
                        ref="otpInput"
                        class="white--text"
                        separator=""
                        :num-inputs="6"
                        :valid="otpValid"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        @onComplete="handleOnComplete"
                      />
                    </div>
                    <v-btn
                      @click="PostSMSCodeAndLogin"
                      class="my-4 white--text moholand-rounded font-weight-bold"
                      elevation="0"
                      color="orange"
                      block
                      x-large
                      :loading="loading"
                    >
                      ورود
                    </v-btn>
                    <v-btn
                      @click="step -= 1"
                      class="my-4 white--text moholand-rounded font-weight-bold"
                      elevation="0"
                      color="orange"
                      block
                      x-large
                      :disabled="loading"
                    >
                      بازگشت به ورود شماره موبایل
                    </v-btn>
                  </v-col>
                  <!-- <v-col cols="12">
            <vue-countdown
              :time=""
              :interval="1000"
              v-slot="{ days, hours, minutes, seconds }"
              class="mx-auto"
            >
              <div class="d-flex justify-center align-center ">
                <div>
                  {{
                    (days > 0 && days + ":") +
                      hours +
                      ":" +
                      minutes +
                      ":" +
                      seconds
                  }}
                </div>
                <v-icon class="mr-1">
                  mdi-clock-outline
                </v-icon>
              </div>
            </vue-countdown>
          </v-col> -->
                </v-row>
              </v-form>
            </v-stepper-content>
          </v-stepper>

          <!-- <v-btn
                        class="my-4"
                        link
                        text
                        outlined
                        color="#727272dd"
                        block
                        @click="forgetPassword = true"
                    >
                        رمز عبورتان را فراموش کرده اید؟
                    </v-btn> -->
          <div style="max-width: 50%" class="mx-auto">
            <div class="mt-8 d-flex align-center">
              <v-divider class="white" />
              <p class="ma-auto">
                قبلا ثبت نام کردی؟
              </p>
              <v-divider class="white" />
            </div>
            <v-btn
              class="my-4 orange--text font-weight-bold moholand-rounded"
              color="white"
              elevation="0"
              block
              x-large
              to="/login"
            >
              ورود در سایت
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-main>
  </v-container>
</template>
<script>
import {
  Constants,
  getErrorText,
  persianNumToEnglish,
} from "../../constants/GlobalConstants";

import Axios from "axios";
import MyAxios from "../../constants/MyAxios";
import OtpInput from "../../components/Otp";
import MySnackbar from "@/components/MySnackbar";
import VFormBase from "vuetify-form-base";
import { formPostPrepare } from "@/constants/PanelFormHandler";
import Otp from "@/components/Otp";

export default {
  name: "Register",
  components: {
    "v-otp-input": OtpInput,
    MySnackbar,
    VFormBase,
    Otp,
  },
  data() {
    return {
      rules: Constants.rules,
      loading: false,
      step: 1,
      otp: "",
      otpValid: true,
      alert: {
        show: false,
        text: "",
        type: "error",
      },
      // otp: "",
      // otpValid: true,
      rules: Constants.rules,
      model: {
        first_name: this.$store.state?.userData?.first_name || "",
        last_name: this.$store.state?.userData?.last_name || "",
        cellphone: this.$store.state?.userData?.cellphone || "",
        email: this.$store.state?.userData?.email || "",
        store_name: "",
        store_url: "",
        is_seller: this.$route.query.seller || false,
        isLawAccepted: false,
      },
      schema: {
        first_name: {
          type: "text",
          outlined: true,
          hideDetails: "auto",
          label: "نام",
          rules: [
            Constants.rules.required,
            // Constants.rules.persian_string,
          ],
          class: "required centered-input moholand-rounded ma-2",
          required: true,
          backgroundColor: "white",
          color: "black",
        },
        last_name: {
          type: "text",
          outlined: true,
          hideDetails: "auto",
          label: "نام خانوادگی",
          rules: [
            Constants.rules.required,
            // Constants.rules.persian_string,
          ],
          class: "required centered-input moholand-rounded ma-2",
          required: true,
          backgroundColor: "white",
          color: "black",
        },
        cellphone: {
          type: "text",
          outlined: true,
          hideDetails: "auto",
          label: "شماره موبایل",
          rules: [
            Constants.rules.required,
            // Constants.rules.persian_string,
            Constants.rules.cellphone,
          ],
          class: "required centered-input moholand-rounded ma-2",
          required: true,
          hint: "نمونه: 09123456789",
          disabled: this.$store.state.clubData?.id ? true : false,
          backgroundColor: "white",
          color: "black",
        },
        email: {
          type: "text",
          outlined: true,
          hideDetails: "auto",
          label: "ایمیل (برای پشتیبانی)",
          class: "required centered-input moholand-rounded ma-0 pa-0 ma-2",
          required: true,
          disabled: this.$store.state.clubData?.id ? true : false,
          backgroundColor: "white",
          color: "black",
        },
        // sellerName: {
        //   type: "text",
        //   outlined: true,
        //   hideDetails: "auto",
        //   label: "نام فروشنده",
        //   rules: [
        //     Constants.rules.required,
        //     // Constants.rules.persian_string,
        //   ],
        //   class: "required centered-input moholand-rounded ma-0 pa-0 ma-2",
        //   required: true,
        //   hint: "نمونه: 09123456789",
        //   disabled: this.$store.state.clubData?.id ? true : false,
        //   backgroundColor: "white",
        //   color: "black",
        //   disabled: true,
        //   order: { order: 3, md: 1 },
        // },
        store_name: {},
        store_url: {},
      },
    };
  },
  watch: {
    "model.is_seller": {
      immediate: true,
      handler(newVal) {
        console.log("isSeller", newVal);
        if (newVal) {
          // this.schema.sellerName.disabled = false;
          // this.schema.store_name.disabled = false;
          // this.schema.store_url.disabled = false;
          // this.schema.store_name.type = "text";
          // this.schema.store_url.type = "text";

          // this.schema.store_name.rules = [Constants.rules.required];
          // this.schema.store_url.rules = [Constants.rules.required];
          this.schema = {
            ...this.schema,

            store_name: {
              type: "text",
              outlined: true,
              hideDetails: "auto",
              label: "نام فروشگاه",
              rules: [Constants.rules.required],
              class: "required centered-input moholand-rounded ma-0 pa-0 ma-2",
              required: true,
              backgroundColor: "white",
              color: "black",
            },
            store_url: {
              type: "text",
              outlined: true,
              hideDetails: "auto",
              label: "لینک فروشگاه",
              rules: [Constants.rules.required],
              class:
                "required centered-input moholand-rounded ma-0 pa-0 ma-2 ltr",
              required: true,
              // prefix: "www.moholand.com/store/",
              hint: "www.moholand.com/store/",
              "persistent-hint": true,
              backgroundColor: "white",
              color: "black",
            },
          };

          this.model = {
            ...this.model,
            store_name: "",
            store_url: "",
          };
        } else {
          // this.schema.sellerName.disabled = true;
          // this.schema.store_name.disabled = true;
          // this.schema.store_url.disabled = true;
          // this.schema.store_name.type = "";
          // this.schema.store_url.type = "";

          // this.schema.store_name.rules = [];
          // this.schema.store_url.rules = [];
          this.schema.store_name = {};
          this.schema.store_url = {};

          this.model = {
            ...this.model,
            store_name: "",
            store_url: "",
          };
        }
        this.$forceUpdate();
      },
    },
  },
  methods: {
    handleOnComplete(value) {
      this.otpValid = true;
      this.otp = value;
      this.PostSMSCodeAndLogin();
    },
    PostRegisterInfo() {
      if (this.$refs["form"].validate()) {
        if (!this.loading) this.loading = true;

        let data = formPostPrepare(this.model);
        data.append("is_seller", +this.model.is_seller);

        Axios.post(Constants.mainURL + "/user/pre/auth", data)
          .then((response) => {
            console.log(response.data);

            this.loading = false;

            this.step += 1;

            // let data = formPostPrepare(this.model);

            // Axios.post(Constants.mainURL + "/user/login", data)
            //   .then((response) => {
            //     console.log(response.data);

            //     let role = response.data.data["roles"][0];

            //     MyAxios.defaults.headers.common["Authorization"] =
            //       "Bearer " + response.data.data[0].access_token;

            //     localStorage.setItem(
            //       "moholandtoken",
            //       JSON.stringify(response.data.data[0])
            //     );

            //     this.$store.commit("setToken", response.data.data[0]);
            //     this.$store.commit("setUserData", {
            //       ...response.data.data[1],
            //       // mainID: response.data.data["role"].id,
            //       roleName: role,
            //     });

            //     if (role == "user") this.$router.replace("/");
            //     else this.$router.replace("Panel");
            //   })
            //   .catch((error) => {
            //     console.log(error, error.response);
            //     this.loading = false;
            //     this.alert = {
            //       show: true,
            //       type: "error",
            //       text: "مشکلی در ثبت نام پیش آمده. لطفا دوباره سعی کنید.",
            //     };
            //   });
          })
          .catch((error) => {
            console.log(error, error.response);
            this.loading = false;
            this.alert = {
              show: true,
              type: "error",
              text: "مشکلی در ثبت نام پیش آمده. لطفا دوباره سعی کنید.",
            };
          });
      }
    },
    PostSMSCodeAndLogin() {
      if (this.otpValid) {
        if (!this.loading) this.loading = true;

        let data = formPostPrepare(this.model);

        data.append("code", persianNumToEnglish(this.otp.trim()));
        data.append("is_seller", +this.model.is_seller);

        Axios.post(
          Constants.mainURL + "/user/auth",
          data /*{
          //   user_id: 1,
          cellphone: this.model.cellphone,
          code: this.otp,
        }*/
        )
          .then((response) => {
            console.log("response", response);

            let role = response.data.data["roles"][0];

            MyAxios.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.data[0].access_token;

            localStorage.setItem(
              "moholandtoken",
              JSON.stringify(response.data.data[0])
            );

            this.$store.commit("setLoginData", {
              token: response.data.data[0],
              userData: {
                ...response.data.data[1],
                roleName: role,
              },
            });
            // this.$store.commit("setToken", response.data.data[0]);
            // this.$store.commit("setUserData", {
            //   ...response.data.data[1],
            //   roleName: role,
            // });

            if (response.data.data[1].id)
              this.updateCart(response.data.data[1].id);

            this.$router.replace("/dashboard");
          })
          .catch((error) => {
            console.log("error", error, error.response);
            this.loading = false;
            this.$root.$emit("toast", { text: getErrorText(response) });
          });
      }
    },
    updateCart(user_id) {
      if (!this.$store.state.cart?.id) return;
      MyAxios.post("carts/" + this.$store.state.cart?.id + "/update", {
        user_id,
      })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response || error);
        });
    },
    goToNextStep() {
      let validate = this.$refs["form" + this.step].validate();
      if (this.step == 2 && this.otp.length < 6) {
        this.otpValid = false;
        validate = false;
      }

      if (validate) this.step = this.step + 1;
      else
        this.alert = {
          show: true,
          type: "error",
          text: "لطفا تمامی فیلد ها را به درستی پر نمایید.",
        };
    },
    goToPreviousStep(currentStep) {
      this.step = this.step - 1;
    },
  },
};
</script>

<style scoped>
.left-bg {
  width: 100vw;
  height: 100vh;
  /* background-image: url("../../assets/login-bg.jpg"); */
  background-position: center center;
  background-size: cover;
}
.height-100 {
  height: 100%;
}
.footer {
  position: absolute;
  bottom: 0;
}
.ltr {
  direction: ltr;
}
.v-stepper__header {
  box-shadow: none;
  border-bottom: 1px solid #ddd;
}

.v-stepper.mt-12.v-stepper--is-booted.v-stepper--alt-labels.theme--light {
  box-shadow: none;
}

.otp-input {
  max-width: 30px;
  height: 30px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  background-color: red;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.codeinput {
  padding: 15px;
  background-color: red;
}
.centered-input >>> input {
  text-align: center;
}
.label {
  font-size: 15px;
  text-align: right;
}
</style>
